/**
 * Define all of your application routes here
 * for more information on routes, see the
 * official documentation https://router.vuejs.org/en/
 */
export default [
    {
      path: '/access/:code',
      name: 'Easy Access',
      view: 'Easy'
    },
    {
      path: '/forgot',
      name: 'Forgot Password',
      view: 'Forgot'
    },
    {
      path: '/finder',
      name: 'Finder',
      view: 'Finder'
    },
    {
      path: '/newpassword',
      name: 'New Password',
      view: 'Newpassword'
    },
    {
      path: '/verify-change-password',
      name: 'Password Verification',
      view: 'VerifyPass'
    },
    {
      path: '/login',
      name: 'Login',
      view: 'Login'
    },
    {
      path: '/confirm',
      name: 'Confirm Payment',
      view: 'Confirm'
    },
    {
      path: '/home',
      name: 'eBoracay',
      view: 'Home'
    },
    {
      path: '/registration',
      name: 'Registration',
      view: 'RegistrationPage'
    },
    {
      path: '/verify',
      name: 'Verification',
      view: 'VerifyPage'
    },
    {
      path: '/bookings',
      name: 'Booking Management',
      view: 'BookingPage'
    },
    {
      path: '/details',
      name: 'Details',
      view: 'Details'
    },
    {
      path: '/payment',
      name: 'Payment',
      view: 'Payment'
    },
    {
      path: '/qr-code',
      name: 'Booking QRcode',
      view: 'Qrcode'
    },
    {
      path: '/cancelled',
      name: 'Payment Cancelled',
      view: 'Cancelled'
    },
    {
      path: '/complete',
      name: 'Payment Complete',
      view: 'Complete'
    },
    {
      path: '/transactions',
      name: 'Transactions',
      view: 'Transactions'
    },
    {
      path: '/single-transaction/:id',
      name: 'View',
      view: 'SingleTransaction'
    },
    {
      path: '/account',
      name: 'Account',
      view: 'AccountPage'
    },
  
  ]
  