<template>
    <v-dialog
    v-model="dailog"
    persistent
    fullscreen>
   
    <v-card>
          <v-toolbar  flat>
            <v-spacer></v-spacer>
            <v-btn fab small elevation="1"  @click="emitToParent('close')">
                <v-icon>mdi-close</v-icon>
              </v-btn>
          </v-toolbar>
          <v-card-text class="pa-1 text-body-2">
            <div style="width: 320px;" class="ma-auto">
              <div v-for="(item,index) in receipts" :key="index + '-r'" style="border: 1px solid #d0d0d0; padding: 15px; margin-top: 5px; margin-bottom:5px;">
                    <div class="text-center" id="orbg">
                    REPULIC OF PHILIPPINES <br/>
                    PROVINCE OF AKLAN<br/>
                    MUNICIPALITY OF MALAY
                  </div>
                  <div class="text-center mt-4">
                    <span class="font-weight-bold">OFFICE OF MUNICIPAL TREASURER</span><br/>
                    Poblacion, Malay, Aklan 
                  </div>
                  <div class="mt-4">
                    EOR: {{item.issued_no}} <br/>
                    DATE:  {{$moment(item.issued_dt).format("MM/DD/YYYY hh:mm:ss A")}}
                  </div>
                  <div class="mt-3">
                    LOCATION CODE: ({{item.loc_code}})
                  </div>
                  <div class="mt-3">
                    PAYOR: {{guest.lastname}}, {{guest.firstname}}
                  </div>
                  <div class="mt-3">
                    OPSN: {{item.opsn}}
                  </div>
                  <div class="mt-3">
                    Nature of Collection:
                  </div>
                  <div>
                    <table style="width: 100%">
                        <tr>
                          <td class="pa-1"> {{item.product_name}}</td>
                          <td class="text-right pa-1"> {{$money(item.amount)}}</td>
                        </tr>
                        <tr>
                        <th class="pa-1"> TOTAL</th>
                        <th class="text-right pa-1">  {{$money(item.amount)}}</th>
                      </tr>
                    </table>
                  
                  </div>

                  <div class="mt-3">
                    Amount Received:<br/>
                    Php {{$money(item.amount)}}
                  </div>

                
                  <div class="mt-3">
                    Mode of payment: GCASH<br/>
                    Transaction No.: <br/>{{item.issued_no}}
                  </div>

                  <div class="mt-6 mb-4 text-center">
                    <span>{{item.agent}}</span>
                    <v-divider></v-divider>
                    <span class="font-italic">Collecting Agent</span>
                  </div>

                  <div class="mt-3 mb-4 text-center">
                    <span>{{item.treasurer}}</span>
                    <v-divider></v-divider>
                    <span class="font-italic">Municipal Treasurer</span>
                  </div>
                </div>    
            </div>
          </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
      show:{
          type: Boolean,
          default: false
      },
      guestID: {
          type: Number,
          default:0
      },
  },
  data: () => ({
    dailog: false,
    agree: false,
    receipts:[],
    guest: {}
    }),
  watch: {
        show(v) {
          v?this.dailog=true:this.dailog=false
          if(v) {
            this.getReceipt()
          }
        }
    },
  methods: {
   emitToParent (action) {
      this.$emit('ConfirmEvent', action)
    },
    getReceipt() {
      this.$http.post("guest/receipt", {id: this.guestID}).then(response => {
          response.data.status? this.receipts = response.data.receipts:this.receipts = []
          response.data.status? this.guest = response.data.guest:this.guest = {}
          }).catch(e => {
              this.NETWORK_ERROR(e)
         })
    }
  }
}
</script>
