<template>
  <v-main class="font-weight-light">
      <v-container>
      <router-view />
    </v-container>
  </v-main>
</template>
<script>
export default {
  metaInfo () {
    return {
      title: 'eBoracay - ' + this.$route.name!=null?this.$route.name:"Welcome"
    }
  }
}
</script>