import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
//Plugins
import './plugins'
import './components'
import 'animate.css';
import router from '@/router'
import store from '@/store'

import VueSession from 'vue-session'
import { API_MIXINS } from '@/mixins/api_mixins.js'
import VueQRCodeComponent from 'vue-qrcode-component'
Vue.component('qr-code', VueQRCodeComponent)
Vue.use(VueSession)
Vue.mixin(API_MIXINS)
Vue.use(require('vue-moment'))

Vue.config.productionTip = false
window.console = console
console.log = function () {}
new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
