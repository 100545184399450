<template>
  <v-dialog
    v-model="dailog"
    width="420"
    persistent
  >
    <v-card>
      <v-card-title
        class="text-h6"
        dense
      >
        <v-icon left>
          mdi-account-edit
        </v-icon>Guest Form:
        <v-spacer />
        <v-icon   @click="emitToParent('close')">mdi-close</v-icon>
       
      </v-card-title>
      <v-divider />
      <v-card-text class="mt-4">
        <v-form>
          <v-row>
           
            <v-col cols="12">
              <v-form  
              ref="form2"
              v-model="valid2"
              lazy-validation
            >
              <v-card elevation="0">
                <v-card-text class="pa-0">
                 
                  <v-row>
                  
                    <v-col cols="6">
                      <v-text-field
                      :rules="nameRules"
                      v-model="guest.firstname"
                      hide-details
                      dense
                      
                        label="First Name"
                      />
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                      hide-details
                      dense
                      :rules="nameRules"
                        label="Last Name"
                        
                        v-model="guest.lastname"
                      />
                    </v-col>
                    <v-col cols="6">
                      <v-autocomplete
                      hide-details
                      v-model="guest.gender"
                      :rules="nameRules"
                      :items="['Male', 'Female']"
                      label="Gender"
                      dense
                    />
                    </v-col>
                    <v-col cols="6">
                    <v-text-field
                    hide-details
                    dense
                    label="Age"
                    :rules="nameRules"
                    v-model="guest.age"
                    type="number"
                  />
                    </v-col>  
                     <v-col cols="6">
                      <v-checkbox
                      hide-details
                    dense
              v-model="guest.student"
              
              label="Student"
              color="success"
              value="yes"
            ></v-checkbox>
                   
                    </v-col>  
                   <v-col cols="6">
                      <v-checkbox
                      hide-details
                    dense
              v-model="guest.pwd"
              
              label="PWD"
              color="success"
              value="yes"
            ></v-checkbox>
                   
                    </v-col>
                    
                    <v-col cols="6">
                      <v-autocomplete
                      hide-details
                          v-model="guest.class"
                          
                          :rules="nameRules"
                          :items="['DOMESTIC', 'FOREIGN', 'OFW']"
                          label="Classification"
                          @change="setCountry()"
                          dense
              />  
                    </v-col>
                  <v-col cols="6">
                      <v-autocomplete
                      hide-details
                      
                      :rules="nameRules"
                      :items="countries"
                      v-model="guest.country"
                      label="Country"
                      item-value="name"
                      item-text="name"
                      
                      dense
                    />
                    </v-col>
                    <v-col cols="6">
                      <v-autocomplete
                      hide-details
                      v-if="guest.class=='DOMESTIC'"
                      :rules="nameRules"
                      :items="regions"
                      v-model="guest.region"
                      label="Region"
                      item-value="name"
                      item-text="name"
                      @change="setProvinces()"
                      dense
                    />
                    </v-col>
                    <v-col cols="6">
                      <v-autocomplete
                      hide-details
                      v-if="guest.class=='DOMESTIC'"
                      :rules="nameRules"
                      :items="provinces"
                      v-model="guest.province"
                      label="State/Province"
                      item-value="name"
                      item-text="name"
                      @change="setMunicipalities()"
                      dense
                    />
                 
                  </v-col>
                  <v-col cols="12">
                    <v-autocomplete
                      hide-details
                      v-if="guest.class=='DOMESTIC'"
                      :rules="nameRules"
                      :items="municipalities"
                      v-model="guest.city"
                      label="City/Municipality"
                      dense
                    />
                 
                </v-col>
                  </v-row>
                
                </v-card-text>
       
          
              </v-card>
             </v-form>
             
            </v-col>
          </v-row>
        </v-form>
        <v-divider/>
      </v-card-text>
      <v-card-actions>
                   <v-btn small v-if="state=='new'" tile   @click="clearForm()">Clear</v-btn>
                   <v-btn small v-if="state!='new'" text color="error" tile>Remove</v-btn>
                   <v-spacer/>
                   <v-btn small tile color="primary" :loading="gloading" @click="validateGuest()" >{{ state=='new'?'Add':'Save' }}</v-btn>
               </v-card-actions>

    </v-card>
  </v-dialog>
</template>
<script>
import {
  mapMutations
} from 'vuex'
  export default {
    props: {
    show: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: () =>{}
    },
    booking: {
      type: Object,
      default: () =>{}
    },
    state: {
      type: String,
      default: ""
    }
    
    },
    data: () => ({
        valid: true,
        valid2: true,
        loading: false,
        gloading: false,
        dailog: false,
        from_menu: false,
        to_menu: false,
        guests: [],
        regions: [],
        provinces: [],
        municipalities:[],
        guest:{},
        from_date: "",
        to_date: "",
        nameRules: [
        v => !!v || 'Field is required'
      ],
      guestRules: [
        v => !!v || 'Field is required'
      ],
    }),
    watch: {
        show(v) {
          v?this.dailog=true:this.dailog=false
          if(v) {
            if(this.state != "new") {
              this.guest = this.data
            }
           
            //this.guestList()
          }
          //  else {
          //   this.guest = {}
          // }
        }
    },
    created() {
    },
    computed: {
      countries() {
        return this.$countries
      },
    
    },
    methods: {
      emitToParent (action) {
        this.$emit('DialogEvent', {action: action})
      },
      setProvinces() {
      var obj = this.$philippines;
      Object.values(obj).forEach((val) => {
        if (this.guest.region == val.region_name) {
          this.provinces = Object.keys(val.province_list);
        }
      });
    },
    setMunicipalities() {
      var obj = this.$philippines;
      Object.values(obj).forEach((val) => {
        if (this.guest.region == val.region_name) {
          Object.entries(val.province_list).forEach(([key, value]) => {
            if (key == this.guest.province) {
              this.municipalities = Object.keys(value.municipality_list);
            }
          });
        }
      });
    },
      initRegions() {
      var items = []
      var entries = Object.entries(this.$philippines);
      entries.forEach(([key, value]) => {
        items.push({key:key, name:value.region_name});
      });
      this.regions = items
    },
      setCountry(){
        console.log("class", this.guest.class)
        if(this.guest.class != "FOREIGN") {
          this.guest.country = 'Philippines'
          this.initRegions()
        } else {
          this.guest.country = ""
        }
      
      },
     
      validateGuest() {
        if (this.$refs.form2.validate()) {
          if(this.state == "new") {
            this.addGuest()
          } else {
            this.updateGuest()
          }
          
        }
      },
     
      // guestList() {
      //   this.$http.post('booking/guests', {booking_id:this.$IsNum(this.booking.id)}).then(response => {
      //       response.data.guests? this.guests = response.data.guests:this.guests = []
      //       console.log(response.data)
      //   }).catch(e => {
      //   console.log(e)
      //   })
      // }, 
      clearForm(){
        this.$refs.form2.reset()
        this.guest = {}
        
      },
      addGuest() {
        this.gloading = true
        this.guest.age = this.$IsNum(this.guest.age)
        this.guest.booking_id = this.$IsNum(this.booking.id)
        this.guest.senior =  this.$IsNum(this.guest.age)>59?'yes':''
        this.guest.ref_no = this.booking.ref_no
        this.guest.firstname = this.$Capitalize(this.guest.firstname)
        this.guest.lastname = this.$Capitalize(this.guest.lastname)
        this.$http.post('guest/new', this.guest).then(response => {
            this.gloading = false
            response.data.status? this.VA_ALERT('success', response.data.message): this.VA_ALERT('error', response.data.message)
           // this.clearForm()
            this.guest.firstname = ""
            this.guest.lastname = ""
            this.guest.age = ""
            this.guest.gender = ""
            this.guest.pwd = ""
            this.guest.senior = ""
            this.valid2 = true
            this.emitToParent("close")
        }).catch(e => {
        console.log(e)
        })
      },
      updateGuest() {
        this.gloading = true
        this.guest.age = this.$IsNum(this.guest.age)
        this.guest.senior =  this.$IsNum(this.guest.age)>59?'yes':''
        this.$http.post('guest/update', this.guest).then(response => {
            this.gloading = false
            response.data.status? this.VA_ALERT('success', response.data.message): this.VA_ALERT('error', response.data.message)
            this.clearForm()
     
            this.emitToParent("close")
        }).catch(e => {
        console.log(e)
        })
      },
      removeGuest(guest) {
       console.log(guest)
        this.$http.post('guest/remove', guest).then(response => {
            response.data.status? this.VA_ALERT('success', response.data.message): this.VA_ALERT('error', response.data.message)
           // this.guestList() 
          
        }).catch(e => {
        console.log(e)
        })
      }
    },
  }
</script>
