import Vue from 'vue'
// Lib imports
import axios from 'axios'
var production = true
Vue.prototype.$app_mode = "production"
var SERVER = "http://localhost:9181"
var GCASH = "http://gcash-callback.eboracay.net/create"
if(production) {
  if(Vue.prototype.$app_mode == "production")
       SERVER = "https://prod-srv.eboracay.net"
  else if(Vue.prototype.$app_mode == "local")
        SERVER = "http://localhost:8189"
}
Vue.prototype.$http = axios.create({
  baseURL: SERVER + '/api/'
})
Vue.prototype.$gcash = axios.create({
  baseURL:GCASH
})

Vue.prototype.$fileserver = SERVER + '/public/' 