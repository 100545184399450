<template>
  <v-app>
    <core-toolbar />
    <core-view />
    <!-- <core-footer /> -->
    <v-snackbar
      v-model="showAlert"
      :color="alertColor"
      :timeout="2000"
       :top="'top'"
     >
      {{alertText }}
    </v-snackbar>
  </v-app>
</template>
<script>
import {
  mapMutations
} from 'vuex'

export default {
  name: 'App',
  
  data: () => ({
  }),
  mounted () {
    this.onResponsiveInverted()
    window.addEventListener('resize', this.onResponsiveInverted)
  },
  computed:{
      overlay() {
         return this.$store.state.overlay
      },
      showAlert: {
        get () {
          return this.$store.state.show_alert
          },
          set (val) {
            this.setAlert(val)
          }
      },
      alertText() {
        return this.$store.state.alert_text
      },
      alertColor(){
          return this.$store.state.alert_color
      },
      windowClass() {
        if(this.$store.state.responsive){
          return "va-reponsive"
        } else {
          return "va-not-reponsive"
        }
      },
      IsLoggedIn(){
         return this.$store.state.is_logged
      },

  },
  methods: {
    ...mapMutations(['setDrawer', 'setResponsive', 'setAlert']),
    reserveEVent() {
      this.setShowBooking(false)
    },
      onResponsiveInverted() {
          if (window.innerWidth < 991) {
           this.setResponsive(true)
          } else {
            this.setDrawer(true)
            this.setResponsive(false)
          }
    },
  }

};
</script>
<style>
  ._term {
    height: 200px;
    overflow-y: scroll;
  }
  .agree-checkbox {
    margin-top: 10px;
    
  }
  .agree-checkbox .v-label {
  font-size: .95em;
} 
  #orbg {
    background:  url('assets/seal.png') no-repeat left center;
    background-size: 55px 55px;
  }
  .bg-top {
    background: #fff url('assets/qrbg.png') no-repeat top center;
    height: 100%;
  }
  .bg-bot {
    background: url('assets/bgbot.png') no-repeat bottom center;
    height: 100%;
  }
  .welcome {
    background: url('assets/bg.png') no-repeat center center;
    background-size: 100% 100%;
    height: 100%;
  }
  ._syslogo {
    position: fixed;
    background: url('assets/icon.png') no-repeat center center;
    background-size: 70px 50px;
    width: 256px;
    height: 256px;
  }
  #va-home .v-toolbar__content {
    padding: 4px 0px !important;
  }
  .w-border {
    border: 1px solid #c7c7c7;
  }
  .guest-table th {
    text-align: left;
    background: #f1f1f1;
    border-bottom: 1px solid #c7c7c7;
    padding: 4px !important;
  }
  .guest-table td {
    
    padding: 4px !important;
  }
  .va-table, .guest-table {
    border-collapse: collapse;
    width: 100%;
  
  }
  .va-table td {
    /* border-left: 1px solid #c7c7c7; */
    border-bottom: 1px solid #c7c7c7;
    padding: 0px
  }
  .va-table.border-bot td {
    border-left: 0 !important;
  }
  .va-table  td input, .va-table  td select {
    background: #f1f1f1;
    padding: 4px 7px;
  }
  .va-table  td label {
            color: #c7c7c7; /* Change this to the desired color */
            display: block; /* Make the label a block element */
            font-size: .8em;
            margin-left: 5px;
            margin-bottom: 1px; /* Add some space between the label and input */
            /* Other styling properties can be added as needed */
        }
    .va-table td input:focus, .va-table td select:focus {
      background: #F1E6C7;
        border-bottom: 1px solid #00d3ee; /* Change the border color when in focus */
        outline: none; /* Remove the default focus outline (use with caution for accessibility) */
        /* Add other styles as needed */
    }
    .va-table td select option {
            background-color: #fff; /* Background color of the options */
            color: #757575; /* Text color of the options */
            font-size: .9em;
            padding: 12px;
            /* Add other styles as needed */
        } 
  .welcome-block {
     color: #fff;
     margin: 70% auto 0 auto;
  }
  .text-white {
    color: #fff;
  }
  .text-success {
    color: #5cb860;
  }
  .text-gray {
    color: #dcdcdc;
  }
  .text-primary {
    color: #EEB06C;
  }
  .text-secondary {
    color: #FECA6E;
  }
  .text-warning {
    color: #FECA6E;
  }
  .text-info {
    color: #00d3ee;
  }
  .va-error {
    font-style: italic;
    font-size: .8em;
    color: #f55a4e;
  }
  .va-table-border-bot th, .va-table-border-bot td {
    border-bottom: 1px solid #f5f5f5;
  }
  #va-guest-list .v-list-item {
    min-height: 32px !important;
    padding: 0 0 !important;
  }
</style>