<template>
    <v-dialog
    v-model="dailog"
    persistent
    width="600">
   
    <v-card>
        
          <v-card-text class="pa-4 ">
              <div class="ma-4 text-center text-warning">
                   
                     {{message}}
              </div>
          </v-card-text>
          <v-card-actions>
              <v-btn large color="error" text @click="emitToParent('close')">
                  {{cancelbtn}}
              </v-btn>
              <v-spacer/>
              <v-btn large color="success" text @click="emitToParent('ok')">
                  {{okbtn}}
              </v-btn>
          </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
      show:{
          type: Boolean,
          default: false
      },
      message: {
            type: String,
          default:''
      },
      okbtn: {
          type: String,
          default:''
      },
      cancelbtn: {
          type: String,
          default:''
      }
  },
  data: () => ({
    dailog: false,
    }),
  watch: {
        show(v) {
          v?this.dailog=true:this.dailog=false
        }
    },
  methods: {
   emitToParent (action) {
      this.$emit('ConfirmEvent', action)
    }
  }
}
</script>
