<template>
    <v-dialog
    v-model="dailog"
    persistent
    fullscreen
    >
    <div class="welcome"></div>
   
   
  </v-dialog>
</template>

<script>
export default {
  props: {
      show:{
          type: Boolean,
          default: false
      },
    
      okbtn: {
          type: String,
          default:''
      },
      cancelbtn: {
          type: String,
          default:''
      }
  },
  data: () => ({
    dailog: false,
    agree: false
    }),
  watch: {
        show(v) {
          v?this.dailog=true:this.dailog=false

          if(this.dailog) {
            setTimeout(()=> {
              this.dailog=false
            }, 2000)
          }
        }
    },
  methods: {
   emitToParent (action) {
      this.$emit('ConfirmEvent', action)
    }
  }
}
</script>
