<template>
  <v-app-bar
    v-model="appbar"

    elevation="0"
    color="white"
    app
    class="font-weight-light"
  >
<img
@click="menu=false, $router.push('/home').catch(err => {})"
:src="$store.state.systemlogo"  
width="60px"
>
    <v-toolbar-title class="text-primary">
      <span class="text-body-2">{{ $route.name }}</span>
    </v-toolbar-title>
    <v-spacer/>
    <v-btn  text  @click="menu=false, $router.push('/finder').catch(err => {})"><v-icon color="info">mdi-qrcode</v-icon></v-btn>
    <!-- <v-menu
      transition="scale-transition"
      v-model="menu"
      location="bottom"
      :close-on-content-click="false"
    >
      <template v-slot:activator="{ props }">
        <v-btn
          fab
          small
          elevation="0"
          @click="menu=true"
          v-bind="props"
        >
        <v-icon small>mdi-menu</v-icon>
        </v-btn>
      </template>

      <v-list dense>
        <v-list-item @click="menu=false, $router.push('/details').catch(err => {})">
          <v-list-item-title >Main</v-list-item-title>
        </v-list-item>
        <v-list-item @click="menu=false, $router.push('/account').catch(err => {})">
          <v-list-item-title >Profile</v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-list-item-title @click="menu=false,$router.push('/transactions').catch(err => {})">Transactions</v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-list-item-title @click="menu=false,logout()">Logout</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu> -->
  </v-app-bar>
</template>
<script>
import {
  mapMutations
} from 'vuex'
  export default {
    data: () => ({
      confirm: false,
      menu: false,
      bar_color: "secondary"
    }),
    computed: {
      appbar() {
        return this.$store.state.appbar
      }
    },
    methods: { 
      ...mapMutations(['setLoggedIn', 'setAppBar', 'setDrawer']),
      drawer() {
        this.$store.state.drawer != true?this.setDrawer(true):this.setDrawer(false)
        this.bar_color != "primary"?this.bar_color="primary":this.bar_color="secondary"
      },
      logout() {
        this.setDrawer(false)
        this.setAppBar(false)
         this.setLoggedIn(false)
         this.$session.destroy(),
         this.$router.push('/')
      }

    }
  }
</script>