<template>
    <v-dialog
    v-model="dailog"
    persistent
    width="430">
   
    <v-card>
        
          <v-card-text class="pa-4 text-body-2">
              <p>
                <span class="text-h6 font-weight-bold">Terms of Service</span>
                <br /> <span>Last Updated: January, 20 2024</span>
              </p>
              <v-divider class="mb-6"></v-divider>
              <div class="_term">
                  <p>
                  <span class="font-weight-bold">Terms and Conditions</span>
                  </p>

                  <p>1. This application requires you to provide information that will require consent.</p>
                  <p>
                  2.  The information will be used for this transaction and for tourism purposes. In compliance with RA 10173 or Data Privacy Act of the Philippines, your data will not be used for other purposes except for the above mentioned.
                  </p>
                  <p>
                  3.  No refunds and discounts will be provided for any online transactions.
                  </p>
                  <p>
                  <span class="font-weight-bold">Privacy Policy Statement</span>
                  </p>
                 <p> 
                  The Municipality of Malay is committed to protecting and respecting the privacy of the personal data it collects in compliance with Republic Act No. 10173 or the Data Privacy Act of 2012, and its implementing rules and regulations. This Privacy Policy statement applies to the following end users of the
                </p>
                  <p>
                  1. Personal Data Collected<br />
                  PGA collects the following personal information:<br />
                  <ul>
                    <li>Email address</li>
                    <li>Mobile Number</li>
                    <li>Full Name</li>
                    <li>Residence Address</li>
                    <li>Birthdate</li>
                    <li>Personal Health Information</li>
                    <li>Nationality</li>
                    <li>Scanning History including scanning station, timestamp</li>
                  </ul>
                </p>
                <p>
                  2. Legal Basis for Personal Data Processing<br />
                  PGA process data with Republic Act No. 10173 or the Data Privacy Act of 2012, and its implementing rules and regulations as its legal basis. In addition, Department of Interior and Local Government (DILG) Memorandum Circular 2020-077, under Section 3.7 mandates that, “... The LGU may make use of any available technology or application that could assist it in its actions relative to contact tracing….” Further, Department of Health (DOH) Administrative Order No. 2020-0015, under Section VII-C, directs local government units “to implement non-pharmaceutical interventions (NPIs) as minimum public health standards to mitigate the threat of COVID-19” which includes physical distancing, contactless transactions, and limited face-to-face interactions.
                  Data is collected primarily for contact tracing of individuals who are suspect, probable or confirmed for COVID-19 and other infectious diseases as well as the people they came in contact with.
                </p>
                <p>
                  3. Data Collection<br />
                  Data collection happens when the user encodes their personal data in the online health declaration form; and when the QR Code of the user is scanned in the scanning stations which both uses a secure connection to the PGA Servers.
                </p>
                <p>
                  4. Information Security<br />
                  PGA implements appropriate security protocols to prevent unauthorized access to personal data. These include encryption of data transmission, validation of user credentials before granting of access, password requirements for login access, user level access and functionality depending on user category and backup procedures. These security protocols are continuously evaluated and updated as the need arises.
                 </p>
                 <p>
                  5. Data Sharing<br />
                  PGA may share data in so far as the Republic Act No. 10173 or the Data Privacy Act of 2012, and its implementing rules and regulations, issuances of the National Privacy Commission (NPC) and other relevant laws allow it.
                </p>
                <p>
                  6. Retention and Deletion<br />
                  PGA retains personal data as long as necessary for processing in accordance to purposes stated herein and in compliance with other relevant and applicable laws particularly its obligations under Republic Act No. 9470 or the National Archives of the Philippines Act of 2007.
                </p>
                <p>
                  7. Access, and Correction of Personal Database<br />
                  Users have the right to access their personal data and any personal profile information that may be incorrect can be changed by the user or requested to be changed.
                </p>
                <p>
                  8. Right to Complain<br />
                  If users believe that their data is processed in violation of their rights, they can send their complaint to touristboracay@gmail.com to resolve or clarify the issue before formally filing with the National Privacy Commission (NPC). 9. Controller and Contact Information This system is owned, developed and managed by the Provincial Government of Aklan located at Provincial Capitol, Barangay Estancia, Municipality of Kalibo, Province of Aklan as the personal information controller. For questions or clarifications about this Privacy Policy Statement, please contact: touristboracay@gmail.com.
                </p>
                  <p>
                    <span class="font-weight-bold">Data Use notice</span>
                  </p>
                  <p>
                    Surepay Technologies Inc is licensed and regulated by the Bangko Sentral ng Pilipinas (BSP). We provide digital payment and system solutions services to a wide variety of customers. We provide the eBoracay web app to provide convenience in the queuing and payment of fees going to and pro the Island. and  By accessing the eBoracay web app through account opening, customers provide certain personally identifiable information. These gathering of data is mandatory to comply with the requirements of LGU of Malay, Province of Aklan and the Coast Guard and maybe subject of collection, use, storage, destruction and disclosure of this personally identifiable information. By opening an account or accessing or subscribing to eBoracay services, you give consent to the processing of your personal data in accordance with the purpose as herein stated.
                  </p>
                </div>
              <v-divider />
              <P class="text-info mt-8">
                <v-checkbox 
                v-model="agree"
                class="agree-checkbox"
                :value="true"
                color="success"
                dense
                label="I have read and agree with the terms and conditions"
                ></v-checkbox>
              </P>

          </v-card-text>
          <v-card-actions>
              <v-btn large color="error" text @click="emitToParent('close')">
                  {{cancelbtn}}
              </v-btn>
              <v-spacer/>
              <v-btn large color="success" text @click="emitToParent('ok')" :disabled="!agree">
                  {{okbtn}}
              </v-btn>
          </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
      show:{
          type: Boolean,
          default: false
      },
    
      okbtn: {
          type: String,
          default:''
      },
      cancelbtn: {
          type: String,
          default:''
      }
  },
  data: () => ({
    dailog: false,
    agree: false
    }),
  watch: {
        show(v) {
          v?this.dailog=true:this.dailog=false
        }
    },
  methods: {
   emitToParent (action) {
      this.$emit('ConfirmEvent', action)
    }
  }
}
</script>
